.menu-link {
  color: #ffffff;
  display: block;
  width: 100%;
}

.ant-menu-item {
  padding: 0 24px !important;
}

.ant-menu-item:hover, .ant-menu-item-selected {
  background-color: #1890ff !important;
}

.ant-drawer-header {
  background-color: #041528 !important;
}

.ant-drawer-title {
  color: #fff;
}

.ant-btn-primary {
  border: none !important;
  color: #fff !important;
}

.ant-drawer-body {
  background-color: #041528 !important;
}
